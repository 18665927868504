
@use '@angular/material' as mat;



.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex
{
  background-color: transparent;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-fill .mat-form-field-flex{
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  padding:0 .75em 0 .75em!important;
}

.mat-calendar-body-selected{
  background-color: $color-grey-2 !important;
}

.mat-form-field-wrapper
{
  padding-bottom: 0px !important;
}
.mat-date-range-input-inner
{

  display: flex;
  align-items: center;
  color: $color-grey-1 !important;
}
.mat-form-field
{
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 12.5px;
  line-height: 17px;
  color: $color-primary;
}

.mat-date-range-input-end-wrapper {
  flex-grow: 1;
  max-width: calc(50% - 25px) !important;
}

.mat-date-range-input-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

td.mat-cell:last-of-type
{
  padding-right: 0.5rem!important;
}

td.mat-cell:first-of-type
{
  padding-left: 0.5rem!important;
}

.nav-periods{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  .mat-form-field-infix {
    width: fit-content !important;
    .mat-date-range-input {
      width: auto;
    }
    .mat-date-range-input-container {
      width: 150px;
    }
    .mat-date-range-input-start-wrapper {
      overflow: visible !important;
    }
    .mat-date-range-input-end-wrapper {
      max-width: unset !important;
    }
  }
}
