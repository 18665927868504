
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'base/colors';
@import 'base/elements';
@import 'base/breakpoints';
@import 'components/buttons';
@import 'components/forms';
@import 'components/tables';
@import 'components/steps';
@import 'components/menus';
@import 'components/dropdowns';
@import 'components/dropzones';
@import 'components/select-images';
@import 'components/custom-material';
@import 'components/periods';
@import 'components/inner-tabs-threat';
@import 'components/inner-cross-tabs-threat';

//tooltip
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

* {
  font-family: 'Nunito', sans-serif;
 }
