a
{
  color: $color-azure-1;
  text-decoration: none;
  &:hover{
    color: $color-azure-2;
  }
}

input[type='checkbox'] {
  accent-color: $color-purple-1;
}
small{
  &.error{
    color: $color-red-1;
  }
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background
{
  background-color: $color-purple-1;
}

.base--overflowtext{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 5px;
}
