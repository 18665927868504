.base--{
  &steps-prime{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    .p-steps{

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .p-steps-item{
          &:before{
            border: none;
          }
          .p-menuitem-link{
            display: flex;
          }
        }
      }
    }
    .steps{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: flex-start;
      width: 100%;
      .step-item{
        cursor: pointer;
        &:last-child{
          .step-line{
            display: none;
          }
        }
        .step-content{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
          .step-icon{
            .circle{
              display: flex;
              justify-content: center;
              align-items: center;
              align-content: center;
              height: 40px;
              width: 40px;
              background-color: $color-white-1;
              border-radius: 50%;
            }
          }
          .step-group{
            .step-strapline{
              font-weight: 800!important;
              font-size: 9.27084px;
              line-height: 13px;
              letter-spacing: 0.1em;
              color: $color-grey-2;
            }
            .step-title{
              font-weight: 800!important;
              font-size: 14.3277px;
              line-height: 20px;
              letter-spacing: -0.01em;
              color: $color-grey-2;
            }
          }
          &.active{
            .step-icon{
              .circle{
                transform: 1s;
                background-color: $color-purple-1;
                box-shadow: 0px 0px 21px rgba(105, 41, 240, 0.43);
                svg{
                  path{

                    fill: $color-white-1;
                  }
                }
              }
            }
            .step-group{
              .step-title{
                color: $color-grey-1;
              }
            }
          }
          &.completed{
            .step-icon{
              .circle{
                transform: 1s;
                background-color: $color-green-1;
                box-shadow: none;
                svg{
                  path{

                    fill: $color-white-1;
                  }
                }
              }
            }
            .step-group{
              .step-title{
                color: $color-grey-1;
              }
            }
          }
        }
        .step-line{
          height: 15px;
          width:20px;
          border-right: 2px solid $color-grey-2;
          margin-top: 7px;
          margin-bottom: 7px;
        }
      }
    }
  }
}

