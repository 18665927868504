.base--{
  &menu{
    .menu-list{
      display: flex;
      flex-direction: column;
      gap: 2.1875rem;
      .menu-item{
        cursor: pointer;
        .menu-item-content{
          display: flex;
          justify-content: flex-start;
          margin-right: 2.125rem;
          gap: 20px;
          &.active{
            .menu-item-active-bar{
              background: $color-purple-1;
            }
            .menu-item-title{
              .menu-item-icon{
                svg{
                  path{
                    fill: $color-purple-1;
                  }
                }
                filter: drop-shadow(0px 0px 21px rgba(105, 41, 240, 0.43));
              }
              .menu-item-label a{
                color: $color-purple-1;
              }
            }
          }
          .menu-item-active-bar{
            width: 6px;
            height: 25px;
            background: transparent;
            border-radius: 0px 5px 5px 0px
          }
          .menu-item-title{
            display: flex;
            align-items: center;
            // width: 70%;
            // gap: 1rem;
            .menu-item-icon{
              // width: 18px;
              // height: 18px;
              margin-right: 1rem;
            }
            .menu-item-label {
              width: 100%;
              white-space: nowrap;
              transition: all 0.3s;
            }
            .menu-item-label a{
              font-style: normal;
              font-weight: 800;
              font-size: 15px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: $color-grey-2;
            }
          }
          .menu-item-alert{
            width: 19px;
            height: 19px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            background-color: transparent;
            color:$color-white-1;
            &.active{
              background-color: $color-purple-1;

            }
          }
        }
      }
    }
  }
}

