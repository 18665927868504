.base{
  &--dropdown-generic{
    cursor: pointer;
    position: relative;
    display: inline-block;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07);
    border-radius: 24px;
    min-width: 110px;
    .dropbtn {
      background-color: $color-white-1;
      color: $color-grey-1;
      padding: 4px 14px;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      border-radius: 24px;

    }

    .dropdown-content {
      display: none;
      width: 100%;
      position: absolute;

      background-color: $color-white-1;
      box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07);
      z-index: 1;
      a {
        cursor: pointer;
        color: $color-grey-1;
        padding: 12px 16px;
        font-size: 13px;
        line-height: 24px;
        text-decoration: none;
        display: block;
        &:hover{
          color: $color-purple-1;
        }
      }
    }
    &:hover{
      .dropbtn{
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        svg{
          -moz-transform: scaleY(-1);
          -o-transform: scaleY(-1);
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
        }
      }
      .dropdown-content {
        display: block;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        a {
          cursor: pointer;
          &:hover{
            background-color: rgba(247, 245, 245, 0.8862745098);
            color: $color-purple-1;
          }
        }
      }
    }
  }
}
