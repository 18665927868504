.base{
  &--dropzone{

    .image-preview{
      width: 137px;
      height: 137px;
      margin-top: 13px;
      margin-bottom: 13px;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        box-shadow: 0px 0px 30px rgba(74, 74, 74, 0.2);
        border-radius: 17px;
      }
    }
    .image-controls{
      display: flex;
      align-items: center;
      gap:10px;
      span{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: $color-grey-2;
      }
    }
    .image-cropper{

      &.show{
        opacity: 1;
      }
      &.hidden{
        opacity: 0;
      }
    }
  .drag-area{
    border: 2px dashed $color-grey-5;
    width: 332px;
    height: 152px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .title{
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      .azure{
        color: $color-azure-1;
        font-style: normal;
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
      }
    }
    .file-types{
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: $color-grey-2;
    }
    .drag-zone{

      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      /* align-items: center; */
      justify-content: center;
      /* align-content: center; */
      opacity: 0;
    }
  }

  }
}

.drag-area.active{
  border: 2px solid purple;
}
.drag-area .icon{
  font-size: 100px;
  color: purple;
}
.drag-area span{
  font-size: 25px;
  font-weight: 500;
  color: purple;
  margin: 10px 0 15px 0;
}
.drag-area button{
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: purple;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}
