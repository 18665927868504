@import '../animations/fading';

.base--{
  &btn-generic{
    padding: 1.25rem 1.5rem;
    font-weight: 800 !important;
    font-size: 15px;
    line-height: 20px;
    border: 1.5px solid $color-azure-1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:$color-azure-1;
    background-color: $color-white-1;
    @extend .fadeIn;
  }
  &btn-type-a{
    cursor: pointer;
    width: 113px;
    height: 36px;
    padding: 8px 18px;
    background: #A8AAB5;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
  }
}
