.select-image-wrapper{
  position: relative;
  .image-dropdown{
    position: relative;
    border-radius: 24px;

    .select-image {
      cursor: pointer;
      min-width: 332px;
      font-size: 100%;
      font-weight: bold;
      border-radius: 0;
      background-color: $color-grey-3;
      color: $color-grey-1;
      appearance: none;
      padding: 17px 23px;
      padding-right: 38px;
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
      border-radius: 24px;

      border:2px solid transparent;
      &.slim {
        max-width: 195px;
        height: 34px;
        padding: 4px 42px 4px 17px;
        box-shadow: 0px 14px 14px rgb(0 0 0 / 7%);
        background-color: $color-white-1;

      }
      &.auto-height {
        height: auto;
      }
      &.readonly{
        background-color: $color-grey-5;
      }
      &.green{
        background-color: $color-green-2;
      }
      &:focus {
        outline: none !important;
        border:2px solid $color-purple-1;
      }
      &.ng-invalid {
        color: $color-grey-2;
      }

      &::-ms-expand {
        display: none;
      }
      &:hover,
      &:focus {
        color: $color-purple-1;
        background-color: transparent;
        ~ .select-icon,
        ~ .select-icon {
          background-color: transparent;
          border-color: #DCDCDC;
        }
      }
      .filter-answer {
        height: 60px;
      }
    }
    .select-icon {
      position: absolute;
      top: 13px;
      right: 4px;
      width: 30px;
      height: 36px;
      pointer-events: none;
      padding-top: 5px;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      border-radius: 24px;
      &.slim {
        top: 0px;
        background: transparent;
      }
      &.readonly{
        background-color: $color-grey-5;
      }
      &.green{
        background-color: $color-green-2;
      }
      & svg.icon {
        transition: fill 0.3s ease;
        fill: white;
      }
    }
  }
  .options-container{
    position: absolute;
    width: fit-content;
    height: fit-content;
    min-width: 647px;
    // max-height: 420px;
    background: $color-white-1;
    padding: 48px 32px;
    margin-top: 5px;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07);
    border-radius: 21px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    .answers-range-wrapper {
      .answers-range {
        display: flex;
        gap: 12px;
      }
    }
    .answer-list-wrapper{
      // overflow-y: scroll;
      .answer-list{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        align-content: center;
        .answer{
          display: flex;
          flex-direction: column;
          gap:7px;
          &.selected{
            .image-wrapper,
            .description {
              box-shadow: 0px 0px 24px rgba(55, 73, 87, 0.42);
            }
          }
          &.not-selected{
            .image-wrapper{
              img {
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
              }
            }
          }
          .image-wrapper{
            cursor: pointer;
            width: 95px;
            height: 95px;
            background: $color-grey-2;
            border-radius: 15px;
            img{
              width: 95px;
              height: 95px;
              border-radius: 15px;
            }
            &:hover{
              box-shadow: 0px 0px 24px rgba(55, 73, 87, 0.42);
            }
          }

          .description{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $color-black-1;
            background: $color-grey-6;
            border-radius: 15px;
            padding: 8px 12px;
            width: 95px;
            cursor: pointer;
          }
        }
      }
    }
    .answer-controls{
      display: flex;
      margin-top: 16px;
      justify-content: flex-end;
      gap: 15px;
      .btn-cancel{
        cursor: pointer;
        background-color: transparent;
        border: none;
        color:  $color-grey-1;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
      .btn-ok{
        background-color: $color-azure-1;
        border: none;
        color:  $color-white-1;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        border-radius: 38px;
        &.invalid,
        &:disabled {
          cursor: not-allowed;
          background-color: $color-grey-2;
        }
      }
    }
  }
}
