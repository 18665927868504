.inner-tabs-wrapper{
  padding: 2.125rem 0.875rem;
  .inner-tabs{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    .inner-tab-answer{
      display: flex;
      .knob-visual {
        position: relative;
        width: 240px;
        height: 240px;
        margin-right: 16px;
        .line-circle {
          width: 240px;
          height: 240px;
          border-radius: 50%;
          background: $color-white-1;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
          background-image: linear-gradient(90deg, $color-grey-7 50%, transparent 50%);
          transform: rotate(-15deg);
          .line {
            background: transparent;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &.green {
              background-image: linear-gradient(var(--deg), #75C478 50%, transparent 50%);
            }
            &.orange {
              background-image: linear-gradient(var(--deg), #E3AC3C 50%, transparent 50%);
            }
            &.red {
              background-image: linear-gradient(var(--deg), #DE6067 50%, transparent 50%);
            }
            .bg-white {
              width: 230px;
              height: 230px;
              border-radius: 50%;
              background: $color-white-1;
            }
          }
        }
        .dot {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: rotate(-15deg) rotate(var(--deg));
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -3px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
          }
          &.green::before {
            background: #75C478;
          }
          &.orange::before {
            background: #E3AC3C;
          }
          &.red::before {
            background: #DE6067;
          }
        }
        .content {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          overflow: hidden;
          .score {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 34%;
            background: $color-black-1;
            color: $color-white-1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .slider-visual {
        display: flex;
        gap: 4px;
        padding-bottom: 0;
        margin-bottom: 16px;
        .image-block {
          display: flex;
          flex-direction: column;
          text-align: center;
          color: $color-white-1;
          background: $color-black-1;
          img {
            width: 200px;
            height: 200px;
            object-fit: cover;
          }
          span {
            font-weight: 700;
            font-size: 15px;
            line-height: 100%;
            padding: 10px;
            &.green {
              color: #75C478;
            }
            &.orange {
              color: #E3AC3C;
            }
            &.red {
              color: #DE6067;
            }
          }
        }
      }
      .answer-wrapper{
        display: flex;
        align-items: center;
        padding: 1.063rem 0.563rem;
        img{
          width: 48px;
          height: 48px;
          border-radius: 15px;
          box-shadow: 0px 0px 24px rgb(55 73 87 / 42%);
        }
        .text {
          width: 88px;
          margin-left: -40px;
        }
      }
      .inner-divisor{
        height: auto;
        width: 10px;
        border-right: 1px solid black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;

      }
      .threats-wrapper{
        margin: 1.063rem 0.563rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;
        .divisor {
          position: absolute;
          border-right: 1px solid #a8aab547;
          height: 100%;
          &.perc-1{
            width: 1px;
          }
          &.perc-25{
            width: 25%;
          }
          &.perc-50{
            width: 50%;
          }
          &.perc-75{
            width: 75%;
          }
          &.perc-100{
            width: 100%;
          }
        }
        .threat-space{
          height: 10px;
        }
        .threat{
          display: flex;
          min-height: 47px;
          .threat-bar{
            height: 47px;
            background-color: $color-grey-1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-white-1;
            position:relative;
            .small-value{
              position: absolute;
              color: $color-grey-1;
              left: calc(100% + 15px);
            }
          }
          .threat-dot-wrapper{
            display: flex;
            position: absolute;
            height: 50%;
            &.one-period {
              height: 100%;
            }
            .dot{
              margin-left: auto;
              width: 5px;
              background-color: $color-grey-2;
              max-height: 47px;
              margin-right: -8px;
            }
          }

          &.purple{
            .threat-bar{
              background-color: $color-purple-1;
            }
          }
          &.azure{
            .threat-bar{
              background-color: $color-azure-1;
            }
          }
          &.grey{
            .threat-dot-wrapper{
              .dot{
                background: #9e9e9e;
              }
            }

          }
          &.green{
            .threat-dot-wrapper{
              .dot{
                background: #75C478;
              }
            }
          }
          &.orange{
            .threat-dot-wrapper{
              .dot{
                background: #E3AC3C;
              }
            }
          }
          &.red{
            .threat-dot-wrapper{
              .dot{
                background: #DE6067;
              }
            }
          }

        }
      }
    }
    .inner-tab-percentages{
      display: flex;
      .empty-wrapper{
        opacity: 0;
        display: flex;
        align-items: center;
        padding: 1.063rem 0.563rem;
        img{
          width: 48px;
          height: 48px;
          border-radius: 15px;
          box-shadow: 0px 0px 24px rgb(55 73 87 / 42%);

        }
        border-right: 1px solid black;
      }
      .percentage-wrapper{
        margin: 2.063rem 0.563rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;
        .divisor {
          position: absolute;
          height: 100%;
          display: flex;
          .perc-value{
            margin-top: 10px;
            margin-left: auto;
            color: #A8AAB5;
            margin-right: -20px;
          }
          &.perc-1{
            width: 1px;
          }
          &.perc-25{
            width: 25%;
          }
          &.perc-50{
            width: 50%;
          }
          &.perc-75{
            width: 75%;
          }
          &.perc-100{
            width: 100%;
          }
        }
      }
    }
  }
}
