.base--tpanel-periods-wrapper{
  display: flex;
  // justify-content: center;
  align-items: center;
  gap: 10px;
  .period{
    display: flex;
    gap: 4px;
    .left-col-wrapper{
      display: flex;
      gap:11px;
      // width: 127px;
      width: 50%;
      height: 99px;
      padding: 9px 14px;
      background: rgb(189, 188, 188);
      border-radius: 8px;
      .info-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        gap:9px;
        .title{
          display: flex;
          align-items: center;
          gap: 8px;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          text-transform: uppercase;
          color: $color-white-1;
        }
        .dates-wrapper{
          display: flex;
          flex-direction: column;
          font-style: normal;
          font-weight: 900;
          font-size: 15px;
          line-height: 20px;
          text-transform: uppercase;
          color: $color-white-1;
          span{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            color: $color-white-1;

          }
        }
      }
    }
    .right-col-wrapper{
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 50%;
      height: 99px;
      padding: 13px 7px;
      background: rgb(189, 188, 188);
      border-radius: 8px;
      &.purple{
        background: $color-purple-1;
      }
      .info-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        gap:9px;
        .title{
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          text-transform: uppercase;
          color: $color-white-1;
        }
        .total-wrapper{
          display: flex;
          flex-direction: column;
          font-style: normal;
          font-weight: 900;
          font-size: 15px;
          line-height: 20px;
          text-transform: uppercase;
          color: $color-white-1;
        }
      }

      .top-wrapper, .bottom-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 127px;
        width: 100%;
        height: 48px;
        padding: 12px 7px;
        background: rgb(189, 188, 188);
        border-radius: 8px;
        text-align: center;
        font-size: 13px;
        line-height: 14px;
        span{
          font-style: normal;
          font-weight: 900;
          text-transform: uppercase;
          color: $color-white-1;

        }
      }
      .top-wrapper{
        line-height: 14px;
        &:first-line {
          line-height: 1;
        }
      }

    }
    &.purple{
      width: 40%;
      .left-col-wrapper{
        background: $color-purple-1;
      }
      .right-col-wrapper{
        .top-wrapper, .bottom-wrapper{
          background: $color-purple-1;
        }
      }
    }
    &.azure{
      width: 40%;
      .left-col-wrapper{
        background: $color-azure-1;
      }
      .right-col-wrapper{
        .top-wrapper, .bottom-wrapper{
          background: $color-azure-1;
        }
      }
    }
    &.pointer{
      cursor: pointer;
    }
  }
  .export-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 8px;
    font-weight: 700;
    color: $color-purple-1;
    cursor: pointer;
  }
}
.base--rpanel-periods-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  .period{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 12px;
    border-radius: 7px;
    &.purple {
      background: $color-purple-1;
    }
    &.azure {
      background: $color-azure-1;
    }
    .left-col-wrapper{
      text-align: center;
      .info-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
        gap:9px;
        .title{
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          text-transform: uppercase;
          color: $color-white-1;
          .icon{
            position: absolute;
          }
          .testo{
            margin-left: 15px;
          }
        }
        .dates-wrapper{
          display: flex;
          flex-direction: column;
          font-style: normal;
          font-weight: 900;
          font-size: 15px;
          line-height: 20px;
          text-transform: uppercase;
          color: $color-white-1;
          span{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            color: $color-white-1;

          }
        }
      }
    }
    .right-col-wrapper{
      display: flex;
      flex-direction: column;
      gap: 2px;
      .top-wrapper, .bottom-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 127px;
        height: 48px;
        padding: 12px 7px;
        background: rgb(189, 188, 188);
        border-radius: 8px;
        text-align: center;
        font-size: 13px;
        line-height: 14px;
        span{
          font-style: normal;
          font-weight: 900;
          text-transform: uppercase;
          color: $color-white-1;

        }
      }
      .top-wrapper{
        line-height: 14px;
        &:first-line {
          line-height: 1;
        }
      }
      .bottom-wrapper{
        &.grey{
          background: #F9F9F9!important;
        }
        &.green{
          background: #75C478!important;
        }
        &.orange{
          background: #E3AC3C!important;
        }
        &.red{
          background: #DE6067!important;
        }
      }
    }
    &.purple{
      .left-col-wrapper{
        background: $color-purple-1;
      }
      .right-col-wrapper{
        .top-wrapper, .bottom-wrapper{
          background: $color-purple-1;
        }
      }
    }
    &.azure{
      .left-col-wrapper{
        background: $color-azure-1;
      }
      .right-col-wrapper{
        .top-wrapper, .bottom-wrapper{
          background: $color-azure-1;
        }
      }
    }
  }
}
