@import '../animations/fading';

.base--{
  &form-generic{
    display: flex;
    flex-direction: column;
    margin: 3%;
    min-height: 60vh;
    gap: 29px;
    .filters-wrapper, .more-filters-wrapper{
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 7px;
      .filter-container{
        justify-content: flex-end;
      }
    }
    .form-group{
      display: flex;
      flex-direction: column;
      gap: 7px;
      @extend .fadeIn;
      label{
        font-weight: 800!important;
        font-size: 16px;
        line-height: 22px;
        color: $color-purple-1;
      }
      input{
        border: 1px solid $color-white-2;
      }

    }
    .actions-group{
      margin-top: auto;
    }
  }
  &form-generic{
    display: flex;
    flex-direction: column;
    margin: 1% 0;
    min-height: 65vh;
    gap: 29px;
    .form-group{
      display: flex;
      flex-direction: column;
      gap: 7px;
      @extend .fadeIn;
      .error{
        color: #ea4153;
      }
      label{
        font-weight: 800!important;
        font-size: 16px;
        line-height: 22px;
        color: $color-purple-1;
      }

      .input-wrapper {
        position: relative;
        border-radius: 24px;
        input{
          width: 215px;
          font-size: 100%;
          background-color: $color-grey-3;
          color: $color-grey-1;
          text-overflow: ellipsis;
          border: none;
          appearance: none;
          padding: 17px 23px;
          padding-right: 38px;
          -webkit-appearance: none;
          -moz-appearance: none;
          transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
          border-radius: 24px;
          border:2px solid transparent;
          &:focus {
            outline: none !important;
            border:2px solid $color-purple-1;
          }
          &.ng-dirty.ng-invalid {
            border: 2px solid #DE6067;
          }
        }
        .input-icon {
          cursor: pointer;
          position: absolute;
          top: 13px;
          right: 4px;
          width: 30px;
          height: 36px;
          padding-top: 5px;
          border-radius: 24px;
        }
        #surveyNewName{
          min-width: 332px;
        }
      }
      .select-wrapper {
        position: relative;
        border-radius: 24px;
        select {
          cursor: pointer;
          min-width: 332px;
          font-size: 100%;
          font-weight: bold;
          border-radius: 0;
          background-color: $color-grey-3;
          color: $color-grey-1;
          appearance: none;
          padding: 17px 23px;
          padding-right: 38px;
          -webkit-appearance: none;
          -moz-appearance: none;
          transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
          border-radius: 24px;
          border:2px solid transparent;
          white-space: break-spaces;
          &.slim {
            max-width: 195px;
            height: auto;
            padding: 4px 42px 4px 17px;
            box-shadow: 0px 14px 14px rgb(0 0 0 / 7%);
            background-color: $color-white-1;

          }
          &.azure{
            background-color: $color-azure-1;
          }
          &.purple{
            background-color: $color-purple-1;
          }
          &.readonly{
            background-color: $color-grey-5;
          }
          &.green{
            background-color: $color-green-2;
          }
          &:focus {
            outline: none !important;
            border:2px solid $color-purple-1;
          }
          &.ng-invalid {
            color: $color-grey-2;
          }

          &::-ms-expand {
            display: none;
          }
          &:hover,
          &:focus {
            color: $color-purple-1;
            background-color: transparent;
            ~ .select-icon,
            ~ .select-icon {
              background-color: transparent;
              border-color: #DCDCDC;
            }
          }
        }
        .select-icon {
          position: absolute;
          top: 13px;
          right: 4px;
          width: 30px;
          height: 36px;
          pointer-events: none;
          padding-top: 5px;
          transition: background-color 0.3s ease, border-color 0.3s ease;
          border-radius: 24px;
          &.slim {
            top: 0px;
            background: transparent;
          }
          &.readonly{
            background-color: $color-grey-5;
          }
          &.green{
            background-color: $color-green-2;
          }
          & svg.icon {
            transition: fill 0.3s ease;
            fill: white;
          }
        }
      }
      .form-row{
        display: flex;
        gap: 20px;

        span {
          display: flex;
          align-items: center;
          &.filter-by{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 40px;
            color: $color-grey-1;
            align-items: inherit;
            height: 24px;
          }
          a {
            cursor: pointer;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $color-grey-2;
          }
        }
        .date-title{
          display: flex;
          align-items: center;
          min-width: 215px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: $color-grey-2;
        }

      }
      .form-column{
        display: flex;
        flex-direction: column;
        gap: 14px;
      }
      .btn-default{
        cursor: pointer;
        background-color: $color-grey-2;
        border: none;
        min-width: max-content;
        max-width: 332px;
        color:  $color-white-1;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        border-radius: 38px;
        display: flex;
        align-items: center;
        &:hover{
          background-color: $color-grey-7;
        }
        &.slim{
          height: 34px;
          padding: 4px 17px;

        }
        &.readonly{
          background-color: $color-grey-5;
        }
        &.green{
          background-color: $color-green-2;
        }
        &.purple{
          background-color: $color-purple-1;
        }
        &.white{
          background-color: $color-white-1;
          color:  $color-black-1;
          border: 1px solid $color-primary;
        }
      }
      .btn-circle{
        cursor: pointer;
        background-color: $color-grey-2;
        border: none;
        max-width: 332px;
        color:  $color-white-1;
        padding: 24px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        &:hover{
          background-color: $color-grey-7;
        }
        svg{
          transform: rotate(45deg);
        }
      }
    }

    .form-actions{
      margin-top: auto;
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      .btn-back{
        cursor: pointer;
        background-color: transparent;
        border: none;
        color:  $color-grey-1;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
      .btn-next{
        background-color: $color-azure-1;
        border: none;
        color:  $color-white-1;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        border-radius: 38px;
        &.invalid{
          background-color: $color-grey-2;
        }
      }
    }
    .fields-wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}
