.inner-cross-tabs-wrapper
{
  padding: 1rem;
  table{
    height: 1px;
    thead {
      tr {
        td {
          text-align: center;
          img {
            width: 95px;
            height: 95px;
            border-radius: 15px;
            // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.35);
            &.knob {
              border-radius: 50%;
            }
            &.green {
              border: 5px solid #75C478;
            }
            &.orange {
              border: 5px solid #E3AC3C;
            }
            &.red {
              border: 5px solid #DE6067;
            }
          }
          .text {
            -ms-writing-mode: tb-rl;
            -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            white-space: nowrap;
            line-height: 100%;
            padding: 8px 0;
          }
          .slider-cross {
            width: 110px;
            border-radius: 15px;
            overflow: hidden;
            margin: auto;
            img {
              width: 50px;
              height: 50px;
              border-radius: 0;
            }
            &.green {
              border: 5px solid #75C478;
            }
            &.orange {
              border: 5px solid #E3AC3C;
            }
            &.red {
              border: 5px solid #DE6067;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-of-type{
            width: 95px;
            background: transparent;
          }
          height: 100%;
          text-align: center;
          background: linear-gradient(
            to top,
            $color-azure-1 0%,
            $color-azure-1 50%,
            $color-purple-1 50%,
            $color-purple-1 100%
          );
          &.one-period {
            background: $color-purple-1;
          }
          .cross-couple{
            max-width: 100px;
            display: flex;
            flex-direction: column;
            margin: auto;
            background: rgb(238 238 238 / 50%);
            height: 100%;
            justify-content: space-around;
            align-items: center;
            .cross-value{
              font-family: 'Nunito';
              font-style: normal;
              font-weight: 600;
              font-size: 12.5px;
              line-height: 17px;
              color: black;
              &:first-of-type {
                color: #fff;
              }
            }
          }

          img {
            width: 95px;
            height: 100%;
            border-radius: 15px;
            // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.35);
            &.knob {
              border-radius: 50%;
            }
            &.green {
              border: 5px solid #75C478;
            }
            &.orange {
              border: 5px solid #E3AC3C;
            }
            &.red {
              border: 5px solid #DE6067;
            }
          }
          .text {
            line-height: 100%;
          }
          .slider-cross {
            width: 110px;
            border-radius: 15px;
            overflow: hidden;
            margin: auto;
            img {
              width: 50px;
              height: 50px;
              border-radius: 0;
            }
            &.green {
              border: 5px solid #75C478;
            }
            &.orange {
              border: 5px solid #E3AC3C;
            }
            &.red {
              border: 5px solid #DE6067;
            }
          }
        }
      }
    }

  }
}
