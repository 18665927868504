$color-primary : #424343;

$color-azure-1:#7FCFC2;
$color-azure-2:#4DB7A6;
$color-azure-3:#E3E7F7;
$color-azure-4:#66C0B2;

$color-green-1:#6FD479;
$color-green-2:#B1D7D1;

$color-red-1: #FF9494;

$color-purple-1:#533B83;
$color-purple-2:#7056A4;
$color-purple-3:#432C72;

$color-white-1: #FFFFFF;
$color-white-2: #EBEEED;

$color-grey-1: #374957;
$color-grey-2: #A8AAB5;
$color-grey-3: #F3F3F3;
$color-grey-4: #f7f5f5e2;
$color-grey-5: #C8D2DC;
$color-grey-6: #F7F7F7;
$color-grey-7: #C1C2C9;

$color-black-1: #2E2E2E;



