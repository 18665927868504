//Material style
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@angular/cdk/overlay-prebuilt.css";

// .table-responsive {
//   display: block;
//   width: 100%;
//   overflow-x: auto;
//   .mat-table {
//       width: 100%;
//       max-width: 100%;
//       margin-bottom: 1rem;
//       display: table;
//       border-collapse: collapse;
//       margin: 0px;
//   }
//   .mat-row,
//   .mat-header-row {
//       display: table-row;
//   }
//   .mat-cell,
//   .mat-header-cell {
//       word-wrap: initial;
//       display: table-cell;
//       padding: 0px 5px;
//       line-break: unset;
//       width: auto;
//       white-space: nowrap;
//       overflow: hidden;
//       vertical-align: middle;
//   }
// }


table{
  &.base{
    &--table-generic{
      outline: none;
      tr{
        cursor: pointer;
        &:hover{
          td{
            color: $color-purple-1;
            background-color: $color-grey-4;
            img{
              box-shadow: 0px 0px 30px rgba(74, 74, 74, 0.2);
            }
          }
        }
        th{
          padding: 12px;
          font-weight: 700!important;
          font-size: 13px;
          line-height: 24px;
          color: $color-grey-2;
          background-color: $color-grey-3;
        }
        td{
          padding: 12px;
          font-weight: 600!important;
          font-size: 15px;
          line-height: 20px;
          color: $color-grey-1;
          img{
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 12px;
            margin-left: 10px;
          }
        }
      }
    }
    &--table-survey{
      width: 100%;

    }
  }
}
//Override Material Theme
.mat-elevation-z8 {
  box-shadow: none;
}
th.mat-header-cell{
  background-color: $color-grey-6;
}
.mat-sort-header-content{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-black-1;
}

table.mat-table{
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
tr.mat-row{
  &.checked{
    background-color: $color-azure-3;
  }
}

